import "./home.css";
import "../../index.css";
import React from "react";
import { Link } from "react-router-dom";
import img1 from "../images/e-massage1.jpg";
import img2 from "../images/massage4.jpg";
import body from "../images/body-to-body.jpg";
import nuru from "../images/nuru.jpg";
import oil from "../images/oil-mssg2.jpg";
import { FaSpa } from 'react-icons/fa';
import { FaQuoteLeft } from 'react-icons/fa';
import { FaQuoteRight } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa';
import { FaRegStar } from 'react-icons/fa';


const Home = () => {
    return (
        <>
            <section className="h-landing">
                <div className="hero">
                    <h2>Welcome To <span className="highlight">CrimsonSpa</span></h2>
                    <p>Here at CrimsonSpa, we offer you a true escape from your heretic world, we make your home a place where you can enjoy peace, comfort and tranquility, while meeting your massage needs. feel your stress float away as our we massage through your body while you transform into a relaxed state of mind</p>
                    <span className="btn">
                        <Link to="/services"><button className="btn3">Services</button></Link>
                        <Link to="/book"><button className="btn2">Book Spa</button></Link>
                    </span>
                </div>

            </section>

            <section className="intro">
                <div className="intro-info">
                    <div className="intro-txt">
                        <h3>Why Get A Massage?</h3>
                        <p>Massage is a proven remedy and health practice. from ancient greek and Roman bath to modern day spas and health club. massage has been recognized for it health enhancing effect. Done properly, massages:</p>
                        <ul>
                            <li><FaSpa /> Enhance Health</li>
                            <li><FaSpa /> Reduce Stress</li>
                            <li><FaSpa /> Relaxes Muscles</li>
                            <li><FaSpa /> Relieve Pain</li>
                            <li><FaSpa /> Encourage Relaxation</li>
                        </ul>
                        <Link to="https://www.self.com/story/benefits-of-massage"><button className="btn4">Read more</button></Link>
                    </div>
                    <div className="intro-img">
                        <img src={img1} alt="" />
                        <img src={img2} alt="" />
                    </div>

                </div>
                <img className="fill" src={oil} alt="" />
            </section>

            <section className="special">
                <div className="s-img">
                    <img src={img2} alt="" />
                </div>
                <div className="s-text">
                    <div className="s-cont">
                        <h4>Lingam Massage <span>special</span></h4>
                        <h6><span>350$</span> / hour: | status: <span>Ongoing</span></h6>
                        <p>Lingam massage is a practice that truly honors the penis and those who have them. We do it to give them pleasure. There is also a tremendous amount of sexual energy or chi in a person's penis, and learning how to stimulate and circulate it is very powerful.</p>
                        <div className="btn">
                            <Link to="/service#lingam"><button className="btn5">View</button></Link>
                            <Link to="/book"><button className="btn4">Book</button></Link>
                        </div>
                    </div>

                </div>
            </section>

            <section className="s-others">
                <div className="others"></div>
                <div className="others"></div>
                <div className="others"></div>
                <div className="others"></div>
                <div className="others"></div>
            </section>

            <section className="testimonia">
                <div className="t-text">
                    <div className="t-heading">
                        <h4>Testimonials</h4>
                        <p>What our happy customer said about us</p>
                    </div>
                    <div className="t-body">
                        <span className="clients">
                            <FaQuoteLeft className="left" />
                            <p>I had an incredeble experience at crimsonspa. the staff was professional and attentive to my needs. The atmostphere was serene, i felt all my stress melt away</p>
                            <h6>LANER ELIZAR</h6>
                            <FaQuoteRight className="right" />
                        </span>
                        <span className="clients">
                            <FaQuoteLeft className="left" />
                            <p>The Monsieur was highly skilled and provided the perfect amount of pressure. i left feeling relaxed, i cant wait to have another session.</p>
                            <h6>BRANDON OLIVER</h6>
                            <FaQuoteRight className="right" />
                        </span>
                        <span className="clients">
                            <FaQuoteLeft className="left" />
                            <p>I opted for two monsieur, Anna and Kiara, the where so good. they went beyond and above to ensure my confort and satisfaction, it was incredible i highly recommend crimsonSpa</p>
                            <h6>STEVE</h6>
                            <FaQuoteRight className="right" />
                        </span>
                    </div>
                </div>
            </section>

            <section className="special-offer">
                <div className="offer-heading">
                    <h4>Special Offer</h4>
                    <p>Other top specials for you</p>
                </div>
                <div className="offer-cont">
                    <div className="double">
                        <div className="detail">
                            <span>
                                <h6>Deep Tissue Massage <span>special</span></h6>
                                <p>140$ per hour</p>
                            </span>
                            <Link to="/service#deep"><button className="btn3">Book</button></Link>

                        </div>
                    </div>
                    <div className="row">
                        <div className="area">
                            <div className="area-txt">
                                <h4>Nuru Massage</h4>
                                <h6><span>350$</span> / hour</h6>
                                <p> nuru massage is an erotic massage that originated in Japan. The masseuse uses his or her entire body to massage the client, providing more bodily contact than found in a traditional massage. </p>
                                <Link to="/services#nuru"><button className="btn4">Book</button></Link>
                            </div>
                            <img src={nuru} alt="" />
                        </div>
                        <div className="area">
                            <img src={body} alt="" />
                            <div className="area-txt">
                                <h4>Body to Body Massage</h4>
                                <h6><span>350$</span> / hour</h6>
                                <p>Body-to-body massage therapy is a type of therapeutic massage where the therapist will use their entire body to massage the client. This type of therapy is beneficial for many reasons</p>
                                <Link to="/services#body2body"><button className="btn4">Book</button></Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="attribute">
                <section className="att-cont">
                    <div className="reason">
                        <FaUser className="r-icon" />
                        <h6>Attentiva and Open Staff</h6>
                        <p>crimsonSpa staff are very professional and open to clients, we make sure to carryout every of your request and we alway make sure to take customers complaints as high priority</p>

                    </div>
                    <div className="reason">
                        <FaRegStar className="r-icon" />
                        <h6>Courtesy</h6>
                        <p>our staff are polite, we focus on you and your spa experience and also make you feel relax and comfortable</p>

                    </div>
                    <div className="reason">
                        <FaClock className="r-icon" />
                        <h6>Quick Response</h6>
                        <p>Here at crimsonspa, we are always ready to take request from you 24/7, once contacted we respond to your message immediately without delay</p>

                    </div>
                </section>

            </section>
        </>
    )
};

export default Home;
