import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import HomeApp from "./Home";
import Services from "./Services";
import Book from "./Book";

const App = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<HomeApp />} />
                    <Route path="/home" element={<HomeApp />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/book" element={<Book />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
};

export default App;