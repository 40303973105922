import React from "react";
import "../../index.css";
import "./nav.css";
import { Link } from "react-router-dom";
import { FaSpa } from "react-icons/fa";

const Navbar = () => {
    const hamburger = document.getElementById("hamburger");

    const toggleNavbar = () => {
        const navbar = document.getElementById("navbar");

        if (navbar.style.display === "flex") {
            navbar.style.display = "none";
        } else {
            navbar.style.display = "flex"
        }
    }

    if (hamburger) {
        hamburger.addEventListener('click', () => {
            hamburger.classList.toggle('active')
        }, false)
    }



    return (
        <>
            <section class="mobile">
                <span className="desp"><FaSpa className="navicon" /></span>


                <button onClick={toggleNavbar} id="hamburger">
                    <span></span>
                    <span></span>
                </button>

            </section>
            <section className="navbar" id="navbar">
                <div className="row1">
                    <div className="colm">
                        <div className="sect ml-0">
                            <h6>Email Address</h6>
                            <p>Crimsonspa092@gmail.com</p>
                        </div>
                        |
                        <div className="sect">
                            <h6>Telegram Number</h6>
                            <p>+11 748 893 469</p>
                        </div>
                        |
                        <div className="sect">
                            <h6>Location</h6>
                            <p>Pennsylvania</p>
                        </div>
                    </div>
                    <div className="colm">

                        <div className="sect">
                            <h6>Language</h6>
                            <p>English</p>
                        </div>
                        <div className="sect">
                            <h6>CEO</h6>
                            <p>Emilia Jade</p>
                        </div>
                        <div className="sect b-icon">

                        </div>
                    </div>
                </div>
                <div className="row2">
                    <div className="logo">
                        <h4>CrimsonSpa</h4>
                    </div>
                    <div className="colm navlink">
                        <ul>
                            <Link className="link" to="/"><li>Home</li></Link>
                            <Link className="link" to="/services"><li>Services</li></Link>
                        </ul>
                    </div>
                    <div className="btn">
                        <Link to="/book"><button className="btn1">Book Now</button></Link>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Navbar;
