import BookNow from "./components/Book/book";
import Navbar from "./components/Navbar/nav";
import Footer from "./components/footer/footer";
import React from "react";

const Book = () => {
    return (
        <>
            <Navbar />
            <BookNow />
            <Footer />
        </>
    )
};

export default Book;