import Home from "./components/Home/home";
import Navbar from "./components/Navbar/nav";
import Footer from "./components/footer/footer";
import React from "react";

const HomeApp = () => {
    return (
        <>
            <Navbar />
            <Home />
            <Footer />
        </>
    )
};

export default HomeApp;