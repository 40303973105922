import "./book.css";
import "../../index.css";
import React from "react";
import emailjs from "@emailjs/browser";

const BookNow = () => {

    const sendEmail = (e) => {
        let name = document.getElementById('Name').value;

        e.preventDefault();

        alert(name + ' you just Successfully booked your massage! you will get a response from us shortly.')

        emailjs.sendForm("service_y2x5xfe", "template_uxds3v9", e.target, "_QpzLb2zZTdgYKoiv");
    }


    return (
        <>
            <section className="landing">
                <div className="land-header">
                    <h2>Book Now</h2>
                    <p>Add your choice to cart</p>
                </div>
            </section>

            <section className="bookList">
                <div className="listCont">
                    <div className="listRow">
                        <h2>Body Massage</h2>
                        <div className="mssgList">
                            <span>Swedish Massage..........$140</span>
                            <span>Deep tissue massage......$140</span>
                            <span>Reflexology...................$140</span>
                            <span>Aromatherapy................$140</span>
                        </div>
                    </div>
                    <div className="listRow erotic">
                        <h2>Erotic Massage</h2>
                        <div className="mssgList">
                            <span>Body to Body Massage..........$350</span>
                            <span>Lingam massage..............$350</span>
                            <span>Yoni Massage..................$350</span>
                            <span>Nuru Massage..................$350</span>
                        </div>
                    </div>
                    <div className="listRow">
                        <h2>Sports Massage</h2>
                        <div className="mssgList">
                            <span>Coming soon...</span>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bookForm">
                <div className="formCont">
                    <div className="formHead">
                        <h4>Fill Booking Form</h4>
                        <p>Enter your choice of massage in the form below</p>
                    </div>
                    <form onSubmit={sendEmail}>
                        <input className="input" type="text" name="to_name" id="Name" placeholder="Name *" required />
                        <input className="input" type="email" name="email_from" id="Email" placeholder="Email *" required />
                        <textarea className="input" name="message" id="order" cols="20" rows="5" placeholder="Enter Choice Of massage" required></textarea>
                        <button type="submit">Book</button>
                    </form>
                </div>
            </section>
        </>
    )
};

export default BookNow;