import Service from "./components/Services/services";
import Navbar from "./components/Navbar/nav";
import Footer from "./components/footer/footer";
import React from "react";

const Services = () => {
    return (
        <>
            <Navbar />
            <Service />
            <Footer />
        </>
    )
};

export default Services;