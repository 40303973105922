import "./services.css";
import "../../index.css";
import React from "react";
import { Link } from "react-router-dom";
import nuru from "../images/nuru.jpg";
import body from "../images/body-to-body.jpg";
import swedish from "../images/swedish-mssg.jpg";
import reflex from "../images/footspa.jpg";
import deep from "../images/deep-tissue2.jpg";
import oil from "../images/oil-mssg.jpg";
import lingam from "../images/lingam2.jpg";
import yoni from "../images/e-massage2.jpg";
import emailjs from "@emailjs/browser";

const Service = () => {

    const sendMail = (e) => {
        e.preventDefault();

        alert('Sent');

        emailjs.sendForm("service_y2x5xfe", "template_6704lci", e.target, "_QpzLb2zZTdgYKoiv");
    }

    return (
        <>
            <section className="landing_hero">
                <div className="land-header">
                    <h2>Our Services</h2>
                    <p>Know more about the type of massages we offer.</p>
                </div>
            </section>

            <section className="s-form">
                <div className="formCont">
                    <div className="formHead">
                        <h4>Contact Form</h4>
                        <p>We will reply you within 30mins of sent message</p>
                    </div>
                    <form onSubmit={sendMail}>
                        <input className="input" type="text" name="name_from" id="Name" placeholder="Name *" required />
                        <input className="input" type="email" name="email_from" id="Email" placeholder="Email *" required />
                        <textarea className="input" name="message" id="order" cols="20" rows="5" placeholder="Enter Message for us" required></textarea>
                        <button type="submit">Send</button>
                    </form>
                </div>
            </section>

            <section className="normal">
                <div className="n-head">
                    <h4>Normal:</h4>
                </div>
                <div className="n-area" id="swedish">
                    <div className="n-txt">
                        <h4>Swedish Massage</h4>
                        <h6><span>140$</span> / hour</h6>
                        <p>Swedish massage is a type of massage therapy that’s primarily known for its relaxation effects. With this technique, a massage therapist targets muscles with a combination of friction, gliding strokes, and kneading.

                            Also known as classical massage, this technique is the most common form of massage therapy in Western countries. It’s also considered a core technique that led to the creation of other types of massage therapy. <Link to="https://www.healthline.com/health/benefits-of-swedish-massage">Read more</Link></p>
                        <Link to="/book"><button className="btn4">Book</button></Link>
                    </div>
                    <img src={swedish} alt="" />
                </div>
                <div className="n-area" id="deep">
                    <img src={deep} alt="" />
                    <div className="n-txt">
                        <h4>Deep Tissue Massage</h4>
                        <h6><span>140$</span> / hour</h6>
                        <p>Deep tissue massage is a massage technique that’s mainly used to treat musculoskeletal issues, such as strains and sports injuries. It involves applying sustained pressure using slow, deep strokes to target the inner layers of your muscles and connective tissues. This helps to break up scar tissue that forms following an injury and reduce tension in muscle and tissue.

                            It may also promote faster healing by increasing blood flow and reducing inflammation. <Link to="https://www.healthline.com/health/deep-tissue-massage">Read more</Link></p>
                        <Link to="/book"><button className="btn4">Book</button></Link>
                    </div>
                </div>
                <div className="n-area" id="oil">
                    <div className="n-txt">
                        <h4>Aromatherapy</h4>
                        <h6><span>140$</span> / hour</h6>
                        <p>Aromatherapy massage combines soft , gentle pressure with the use of essential oils. essential oils are diluted before being applied to the skin. During the massage, you will be given a full body massage while inhaling essential oils through a diffuser and absorbing them through your skin. <Link to="https://www.healthline.com/health/what-is-aromatherapy-massage">Read more</Link></p>
                        <Link to="/book"><button className="btn4">Book</button></Link>
                    </div>
                    <img src={oil} alt="" />
                </div>
                <div className="n-area" id="foot">
                    <img src={reflex} alt="" />
                    <div className="n-txt">
                        <h4>Reflexology Massage</h4>
                        <h6><span>140$</span> / hour</h6>
                        <p>This Type of Massage involves applying pressure to specific points on your feet, hands, or ears that correspond to different organs and system in your body. Reflexology can help reduce stress and anxiety, improve circulation and promote relaxation. Reflexology is based on the principle that there are reflexes in the Hands and feet that relate to every organ, gland, and system of the body.</p>
                        <Link to="/book"><button className="btn4">Book</button></Link>
                    </div>
                </div>
            </section>

            <section className="sensual">
                <section className="normal">
                    <div className="n-head">
                        <h4>Sensual:</h4>
                    </div>
                    <div className="n-area" id="nuru">
                        <div className="n-txt">
                            <h4>Nuru Massage</h4>
                            <h6><span>350$</span> / hour</h6>
                            <p>To put it simply, a nuru massage is an erotic massage that originated in Japan. The masseuse uses his or her entire body to massage the client, providing more bodily contact than found in a traditional massage. The word “nuru” stems from a Japanese word for “slippery.” Why? Both the client and the masseuse are covered in Nuru massage gel. The gel is colorless, odorless and actually made of natural Nori Seaweed!<Link to="https://www.entitymag.com/what-is-a-nuru-massage/">Read more</Link></p>
                            <Link to="/book"><button className="btn4">Book</button></Link>
                        </div>
                        <img src={nuru} alt="" />
                    </div>
                    <div className="n-area" id="body2body">
                        <img src={body} alt="" />
                        <div className="n-txt">
                            <h4>Body to Body Massage</h4>
                            <h6><span>350$</span> / hour</h6>
                            <p>Body-to-body massage is a type of massage in which a therapist will work on a client’s body from head to toe. During this type of massage therapy, the therapist will work on the client’s partner in addition to the client’s body.

                                It is a form of sensory, or non-verbal, a massage where the therapist will use their hands to rub the body of the client and their partner simultaneously. the therapist will use their entire body to massage the client. This type of therapy is beneficial for many reasons, which will answer the question of what are the benefits of body massage. These are some of them. <Link to="https://healthtipsrox.com/body-to-body-massage-therapy/">Read more</Link></p>
                            <Link to="/body"><button className="btn4">Book</button></Link>
                        </div>
                    </div>
                    <div className="n-area" id="lingam">
                        <div className="n-txt">
                            <h4>Lingam Massage</h4>
                            <h6><span>350$</span> / hour</h6>
                            <p>A lingam massage is a tantric sex practice focused on massaging the penis. Unlike your average hand job, the lingam massage involves not only massaging and stroking the penis but can also incorporate more advanced techniques, including the testicles, perineum, and the prostate (aka the Sacred Spot) as well. The lingam massage isn't about having one orgasm and being done. Instead, it's about trying to feel more and more pleasure that will become waves of multiple orgasms throughout the massage. Yes, multiple orgasms aren't just for people with clitorises! <Link to="https://www.mindbodygreen.com/articles/tantric-lingam-massage">Read more</Link></p>
                            <Link to="/body"><button className="btn4">Book</button></Link>
                        </div>
                        <img src={lingam} alt="" />
                    </div>
                    <div className="n-area" id="yoni">
                        <img src={yoni} alt="" />
                        <div className="n-txt">
                            <h4>Yoni Massage</h4>
                            <h6><span>350$</span> / hour</h6>
                            <p>Yoni massage is a type of sensual massage. It is one of the tantric practices that aim to create intimate connections between people.  main goal of yoni massage is to help a person feel more in tune with their body and more comfortable in their skin. Some proponents believe that practicing this massage may help a person work through sexual trauma because it helps a person take control of their sexuality and learn what they enjoy. <Link to="https://www.medicalnewstoday.com/articles/yoni-massage#possible-benefits">Read more</Link></p>
                            <Link to="/book"><button className="btn4">Book</button></Link>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
};

export default Service;