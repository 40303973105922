import "./footer.css";
import { FaSpa } from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <section className="footer">
                <span><FaSpa /> CrimsonSpa</span>
                <p>copyright @ 2021 CrimsonSpa</p>
            </section>
        </>
    )
};
export default Footer;